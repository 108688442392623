define('ember-js-getting-started/components/download-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var getDtVersion = function getDtVersion(self, token, page, perPage) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/versions/followshop',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {
        self.set('loadingDtVersion', false);

        self.set('dtVersion', response);

        var dtVersionLessDot = self.get('dtVersion.version').replace(".", "").replace(".", "").replace(".", "");
        self.set('versionLessDots', dtVersionLessDot);

        //self.set('url',self.get('url') + '_Instalador' + '.exe');
        self.set('url', self.get('url'));
      },
      failure: function failure(response) {
        self.set('loadingDtVersion', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loadingDtVersion', false);
        console.log(response);
      }
    });
  };

  var getDtVersionMobile = function getDtVersionMobile(self, token, page, perPage) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/versions/followshop/android',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {
        self.set('dtMobileVersion', response);
        var dtVersionLessDot = self.get('dtMobileVersion.version').replace(".", "").replace(".", "").replace(".", "");
        self.set('versionMobileLessDots', dtVersionLessDot);
      },
      failure: function failure(response) {
        self.set('loadingDtVersion', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loadingDtVersion', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({
    routing: Ember.inject.service('-routing'),
    authenticationService: Ember.inject.service('auth-token-service'),

    loadingDtVersion: true,

    showTwoFactorsExemple: false,

    learnNowVideo: false,

    url: 'http://forgram.com.br/download/Forgram_Instalador.exe',

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getDtVersion(this);
      getDtVersionMobile(this);
    }),

    dtVersion: function () {
      return null;
    }.property('dtVersion'),

    versionLessDots: function () {
      return null;
    }.property('versionLessDots'),

    actions: {
      showTwoFactorsExemple: function showTwoFactorsExemple() {
        if (this.get('showTwoFactorsExemple') === true) this.set('showTwoFactorsExemple', false);else this.set('showTwoFactorsExemple', true);
      },
      learnNowVideo: function learnNowVideo() {
        if (this.get('learnNowVideo') === true) this.set('learnNowVideo', false);else this.set('learnNowVideo', true);
      },
      download: function download() {
        $("#alert").modal('show');
      },
      executeDownload: function executeDownload() {
        window.open('https://followshop-service.s3-sa-east-1.amazonaws.com/download/Followshop' + this.get('versionLessDots') + '.zip', "_self");
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      }
    }

  });
});