define("ember-js-getting-started/helpers/phone-mask", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.phoneMask = phoneMask;
  var helper = Ember.Helper.helper;
  function phoneMask(params /*, hash*/) {
    var v = params[0];
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d\d)(\d)/g, "($1) $2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");

    return v;
  }

  exports.default = helper(phoneMask);
});