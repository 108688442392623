define('ember-js-getting-started/components/credit-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var getTotalCredits = function getTotalCredits(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/credits',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalCreditsLoading', false);
        self.set('totalCredits', response);
      },
      failure: function failure(response) {
        self.set('totalCreditsLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('totalCreditsLoading', false);
        console.log(response);
      }
    });
  };

  var _getVigencyCredits = function _getVigencyCredits(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/credits/vigency',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('tableCreditsLoading', false);
        self.set('credits', response);
      },
      failure: function failure(response) {
        self.set('tableCreditsLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('tableCreditsLoading', false);
        console.log(response);
      }
    });
  };

  var _getExpiredCredits = function _getExpiredCredits(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/credits/expired',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('tableCreditsLoading', false);
        self.set('credits', response);
      },
      failure: function failure(response) {
        self.set('tableCreditsLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('tableCreditsLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({

    authenticationService: Ember.inject.service('auth-token-service'),

    routing: Ember.inject.service('-routing'),

    totalCreditsLoading: true,

    tableCreditsLoading: true,

    vigencyTable: true,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      _getVigencyCredits(this, token);

      getTotalCredits(this, token);
    }),

    totalCredits: function () {
      return null;
    }.property('totalCredits'),

    credits: function () {
      return null;
    }.property('vigencyCredits'),

    actions: {
      getVigencyCredits: function getVigencyCredits() {
        this.set('vigencyTable', true);
        this.set('tableCreditsLoading', true);
        _getVigencyCredits(this, this.get('authenticationService').get());
      },
      getExpiredCredits: function getExpiredCredits() {
        this.set('vigencyTable', false);
        this.set('tableCreditsLoading', true);
        _getExpiredCredits(this, this.get('authenticationService').get());
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      }
    }

  });
});