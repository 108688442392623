define('ember-js-getting-started/components/user-profile-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var getUser = function getUser(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/customers',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      success: function success(response) {
        self.set('userInfoLoading', false);
        self.set('userProfile', response);
        self.set('userProfile.gravatar', token.gravatar);
      },
      failure: function failure(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({

    userInfoLoading: true,

    authenticationService: Ember.inject.service('auth-token-service'),

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);
      this.set('user', token.extras.user);
      getUser(this, token);
    }),

    userAdditional: function () {
      return null;
    }.property('userAdditional'),

    userProfile: function () {
      return null;
    }.property('userProfile')

  });
});