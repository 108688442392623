define('ember-js-getting-started/routes/panel', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),

    actions: {
      willTransition: function willTransition(transition) {
        var token = this.get('authenticationService').get();
        var self = this;
        if (token !== undefined && token !== null && token !== '') {
          Ember.$.ajax({
            url: _environment.default.APP.connectfaceServiceUrl + '/logins',
            type: 'GET',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            success: function success(response) {
              if (response.token !== null && response.token !== undefined) {
                if (response.expired === false) {
                  //nothing
                } else {
                  self.get('authenticationService').logoff();
                  self.get('routing').transitionTo('login');
                }
              } else {
                self.get('authenticationService').logoff();
                self.get('routing').transitionTo('login');
              }
            },
            failure: function failure(response) {
              console.log(response);
              self.get('authenticationService').logoff();
              self.get('routing').transitionTo('login');
            },
            error: function error(response) {
              console.log(response);
              self.get('authenticationService').logoff();
              self.get('routing').transitionTo('login');
            }
          });
        } else {
          this.get('authenticationService').logoff();
          self.get('routing').transitionTo('login');
        }
      }
    }
  });
});