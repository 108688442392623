define("ember-js-getting-started/services/util-service", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Service$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Service = Ember.Service;
  exports.default = Service.extend((_Service$extend = {
    dynamicSort: function dynamicSort(property, form) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      if (form == 1) {
        return function (a, b) {
          var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      } else {
        return function (a, b) {
          var result = a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }
    },
    cnpjMask: function cnpjMask(v) {
      v = v.replace(/\D/g, "");
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
      return v;
    },
    cpfMask: function cpfMask(v) {
      v = v.replace(/\D/g, "");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      return v;
    },
    phoneMask: function phoneMask(v) {
      v = v.replace(/\D/g, "");
      v = v.replace(/^(\d\d)(\d)/g, "($1) $2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
      return v;
    },
    onlyNumber: function onlyNumber(value) {
      if (/\D/g.test(value)) {
        value = value.replace(/\D/g, '');
      }
      return value;
    }
  }, _defineProperty(_Service$extend, "onlyNumber", function onlyNumber(value, greatestNumber) {
    if (/\D/g.test(value)) {
      value = value.replace(/\D/g, '');
    }
    if (value > greatestNumber) {
      return greatestNumber;
    } else {
      return value;
    }
  }), _defineProperty(_Service$extend, "onlyLetters", function onlyLetters(value) {
    var rgx = new RegExp("^[a-zA-Z ]+$");
    return value.match(rgx);
  }), _defineProperty(_Service$extend, "onlyLettersUpperCase", function onlyLettersUpperCase(value) {
    var rgx = new RegExp("^[A-Z ]+$");
    return value.toUpperCase().match(rgx);
  }), _defineProperty(_Service$extend, "onlyFloatNumber", function onlyFloatNumber(value) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return value.match(rgx);
  }), _defineProperty(_Service$extend, "isEmail", function isEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }), _Service$extend));
});