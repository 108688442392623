define('ember-js-getting-started/helpers/credit-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.creditType = creditType;
  var helper = Ember.Helper.helper;
  function creditType(params /*, hash*/) {
    var value = params[0];
    if (value !== undefined) {
      if (value === 'RECURRENCY') return 'RECORRENTE';else return 'EXTRA';
    } else {
      return null;
    }
  }

  exports.default = helper(creditType);
});