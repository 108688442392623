define('ember-js-getting-started/routes/cutecute', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    redirect: function redirect() {
      this.transitionTo('login');
    }
  });
});