define('ember-js-getting-started/services/cart-service', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;


  var checkCartCreditsStorage = function checkCartCreditsStorage() {
    if (localStorage.getItem('cfwhats-panel:cart-credits') === null || localStorage.getItem('cfwhats-panel:cart-credits') === "") {
      return false;
    }
    return true;
  };

  exports.default = Service.extend({
    setCartCredits: function setCartCredits(obj) {
      localStorage.setItem('cfwhats-panel:cart-credits', JSON.stringify(token));
    },
    getCartCredtis: function getCartCredtis() {
      if (checkCartCreditsStorage()) {
        return JSON.parse(localStorage.getItem('cfwhats-panel:cart-credits'));
      }
    }
  });
});