define("ember-js-getting-started/helpers/postal-code-mask", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.postalCodeMask = postalCodeMask;
  var helper = Ember.Helper.helper;
  function postalCodeMask(params /*, hash*/) {
    var v = params[0];
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{5})(\d)/, "$1-$2");
    return v;
  }

  exports.default = helper(postalCodeMask);
});