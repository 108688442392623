define('ember-js-getting-started/routes/login', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    //Verificando autenticação 
    beforeModel: function beforeModel() {
      var token = this.get('authenticationService').get();
      if (token !== undefined && token !== null && token !== '') {
        this.transitionTo('panel.general');
      } else {
        this.get('authenticationService').logoff();
        this.transitionTo('login');
      }
    }
  });
});