define('ember-js-getting-started/components/machine-manager-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var getMachines = function getMachines(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/userMachineSlots/list',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('machinesLoading', false);
        self.set('machines', response);
      },
      failure: function failure(response) {
        self.set('machinesLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('machinesLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),

    machinesLoading: true,

    idToRemoveMachine: null,

    machines: function () {
      return null;
    }.property('machines'),

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getMachines(this, token);
    }),

    actions: {
      openRemoveMachineModal: function openRemoveMachineModal(id) {
        this.set('idToRemoveMachine', id);
        $("#removeMachineModal").modal("show");
      },
      openRemoveAllMachineModal: function openRemoveAllMachineModal() {
        $("#removeAllMachineModal").modal("show");
      },
      remove: function remove(machine) {
        var self = this;

        self.set('machinesLoading', true);

        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/userMachineSlots/' + machine,
          type: 'DELETE',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': self.get('authenticationService').get().token
          },
          success: function success(response) {
            self.set('machinesLoading', false);
            self.set('machines', response);
            self.set('totalUsers.qt', self.get('totalUsers.qt') - 1);
            $("#remove").modal('hide');
          },
          failure: function failure(response) {
            self.set('machinesLoading', false);
            console.log(response);
            $("#remove").modal('hide');
          },
          error: function error(response) {
            self.set('machinesLoading', false);
            console.log(response);
            $("#remove").modal('hide');
          }
        });
      },
      removeAll: function removeAll() {
        var self = this;

        self.set('machinesLoading', true);

        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/userMachineSlots/full',
          type: 'DELETE',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': self.get('authenticationService').get().token
          },
          success: function success(response) {
            self.set('machinesLoading', false);
            self.set('machines', response);
            self.set('totalUsers.qt', self.get('totalUsers.qt') - 1);
            $("#remove").modal('hide');
          },
          failure: function failure(response) {
            self.set('machinesLoading', false);
            console.log(response);
            $("#remove").modal('hide');
          },
          error: function error(response) {
            self.set('machinesLoading', false);
            console.log(response);
            $("#remove").modal('hide');
          }
        });
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      }
    }

  });
});