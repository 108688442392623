define("ember-js-getting-started/helpers/format-date-ddmmyyyy-hhmmss", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDateDdmmyyyyHhmmss = formatDateDdmmyyyyHhmmss;
  var helper = Ember.Helper.helper;
  function formatDateDdmmyyyyHhmmss(params) {
    var value = params[0],

    //offset para trabalhar com adiantamento ou atraso de hora
    offset = 0,
        d = new Date(value),
        milisegundos_com_utc = d.getTime() + d.getTimezoneOffset() * 60000,
        dateGMTBrazil = new Date(),
        //milisegundos_com_utc + (3600000 * offset)
    dformat = ("00" + dateGMTBrazil.getDate()).slice(-2) + "/" + ("00" + (dateGMTBrazil.getMonth() + 1)).slice(-2) + "/" + dateGMTBrazil.getFullYear() + " " + ("00" + dateGMTBrazil.getHours()).slice(-2) + ":" + ("00" + dateGMTBrazil.getMinutes()).slice(-2) + ":" + ("00" + dateGMTBrazil.getSeconds()).slice(-2);
    return dformat;
  }

  exports.default = helper(formatDateDdmmyyyyHhmmss);
});