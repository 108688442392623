define('ember-js-getting-started/components/buy-insta-user-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var Route = Ember.Route;


  var getUserInstaPacakges = function getUserInstaPacakges(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/userInsta/packages',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('userInstaPackagesLoading', false);
        self.set('userInstaPackages', response);
      },
      failure: function failure(response) {
        self.set('userInstaPackagesLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userInstaPackagesLoading', false);
        console.log(response);
      }
    });
  };

  var getUser = function getUser(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('userInfoLoading', false);
        self.set('user', response);
      },
      failure: function failure(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({
    routing: Ember.inject.service('-routing'),

    authenticationService: Ember.inject.service('auth-token-service'),

    userInstaPackagesLoading: true,

    userInstaLoading: true,

    buyLoading: false,

    userInstaPackages: function () {
      return null;
    }.property('userInstaPackages'),

    cartItens: function () {
      return null;
    }.property('cartItens'),

    viewCart: function () {
      return null;
    }.property('cartItens'),

    user: function () {
      return null;
    }.property('user'),

    cartTotalPrice: 0,
    cartTotalUserInsta: 0,

    cartTotalPrice2: 0,
    singleCreditPrice: 0,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getUserInstaPacakges(this, token);
      getUser(this, token);

      this.set('cartItens', new Array());
    }),

    actions: {
      addToCart2: function addToCart2(pack) {
        var inCart = false;
        this.set('viewCart', null);

        if (this.get('cartItens').length > 0) {
          for (var i = 0; i < this.get('cartItens').length; i++) {
            if (pack !== undefined && this.get('cartItens') !== undefined) {
              if (pack.id === this.get('cartItens')[i].id) {
                inCart = true;
                Ember.set(this.get('cartItens')[i], "qt", this.get('cartItens')[i].qt + 1);
              }
            }
          }
        }

        //add novos itens repetidos
        if (inCart === true) {
          this.set('cartTotalUserInsta', this.get('cartTotalUserInsta') + pack.userInstaPackage.quantity);
          this.set('viewCart', this.get('cartItens'));
        }

        //add o primeiro item
        if (inCart === false) {
          Ember.set(pack, "qt", 1);
          this.get('cartItens').pushObject(pack);
          this.set('cartTotalUserInsta', this.get('cartTotalUserInsta') + pack.userInstaPackage.quantity);
          this.set('viewCart', this.get('cartItens'));
        }

        for (var i = 0; i < this.get('userInstaPackages').length; i++) {
          if (this.get('userInstaPackages') !== undefined) {
            if (this.get('cartTotalUserInsta') >= this.get('userInstaPackages')[i].userInstaPackage.quantity) {
              this.set('cartTotalPrice2', this.get('cartTotalUserInsta') * (this.get('userInstaPackages')[i].value / this.get('userInstaPackages')[i].userInstaPackage.quantity));
              this.set('singleCreditPrice', this.get('userInstaPackages')[i].value / this.get('userInstaPackages')[i].userInstaPackage.quantity);
            }
          }
        }
      },
      removeFromCart2: function removeFromCart2(pack) {
        this.set('viewCart', null);

        this.set('cartTotalUserInsta', this.get('cartTotalUserInsta') - pack.userInstaPackage.quantity);

        if (this.get('cartItens').length > 0) {
          for (var i = 0; i < this.get('cartItens').length; i++) {
            if (pack !== undefined && this.get('cartItens') !== undefined) {
              if (pack.id === this.get('cartItens')[i].id) {
                if (this.get('cartItens')[i].qt > 0) {
                  if (this.get('cartItens')[i].qt >= 1) {
                    Ember.set(this.get('cartItens')[i], "qt", this.get('cartItens')[i].qt - 1);
                  }
                  if (this.get('cartItens')[i].qt === 0) {
                    var toRemove = this.get('cartItens').findBy('id', pack.id);
                    if (!toRemove) {
                      // deal with error
                      return;
                    }
                    this.get('cartItens').removeObject(toRemove);
                  }
                }
              }
            }
          }

          for (var i = 0; i < this.get('userInstaPackages').length; i++) {
            if (this.get('userInstaPackages') !== undefined) {
              if (this.get('cartTotalUserInsta') === 0) {
                this.set('cartTotalPrice2', 0);
                this.set('singleCreditPrice', 0);
              }

              if (this.get('cartTotalUserInsta') >= this.get('userInstaPackages')[i].userInstaPackage.quantity) {
                this.set('cartTotalPrice2', this.get('cartTotalUserInsta') * (this.get('userInstaPackages')[i].value / this.get('userInstaPackages')[i].userInstaPackage.quantity));
                this.set('singleCreditPrice', this.get('userInstaPackages')[i].value / this.get('userInstaPackages')[i].userInstaPackage.quantity);
              }
            }
          }
        }

        this.set('viewCart', this.get('cartItens'));
      },
      buy: function buy() {
        this.set('buyLoading', true);
        var self = this;
        var checkout = _environment.default.APP.checkoutUrlNovo;
        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/shopping/userInstaCart/create',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': this.get('authenticationService').get().token
          },
          data: JSON.stringify(this.get('viewCart')),
          success: function success(response) {
            var url = checkout + '/app/cfwhats&cart=' + response.cartKey;
            window.location.assign(url);
          },
          failure: function failure(response) {
            self.set('buyLoading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('buyLoading', false);
            console.log(response);
          }
        });
      },
      buyUpdate: function buyUpdate() {
        this.set('buyLoading', true);
        var self = this;
        var checkout = _environment.default.APP.checkoutUrlNovo;
        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/shopping/cart/userInsta/update',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': this.get('authenticationService').get().token
          },
          data: JSON.stringify(this.get('viewCart')),
          success: function success(response) {
            self.set('buyLoading', false);
            console.log(response);

            if (response.code === 172) {
              alert('Pedido inválido.');
            } else {
              alert('Sua assinatura foi atualizada com sucesso.');
              self.get('routing').transitionTo('panel.general');
            }
          },
          failure: function failure(response) {
            self.set('buyLoading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('buyLoading', false);
            console.log(response);
          }
        });
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      touserInstaManager: function touserInstaManager() {
        this.get('routing').transitionTo('panel.userInsta-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyuserInsta: function toBuyuserInsta() {
        this.get('routing').transitionTo('panel.buy-userInsta');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      }
    }
  });
});