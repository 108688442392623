define('ember-js-getting-started/services/auth-token-service', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;


  var checkStorage = function checkStorage() {
    if (localStorage.getItem('cfwhats-panel:authentication') === null || localStorage.getItem('cfwhats-panel:authentication') === "") {
      return false;
    }
    return true;
  };

  exports.default = Service.extend({
    logoff: function logoff() {
      localStorage.setItem('cfwhats-panel:authentication', null);
    },
    login: function login(token) {
      localStorage.setItem('cfwhats-panel:authentication', JSON.stringify(token));
    },
    get: function get() {
      if (checkStorage()) {
        return JSON.parse(localStorage.getItem('cfwhats-panel:authentication'));
      }
    }
  });
});