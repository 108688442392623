define('ember-js-getting-started/components/infos-tutorials-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var getHelpFaq = function getHelpFaq(self) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/helps/faq',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {
        self.set('helpFaqLoading', false);
        self.set('helpFaqs', response);
      },
      failure: function failure(response) {
        self.set('helpFaqLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('helpFaqLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({
    showChips: false,
    showCampaign: false,
    showOptout: false,
    showPublic: false,
    showChatbot: false,
    startCampaign: false,
    scheduleCampaign: false,
    showMsgManager: false,
    showSendMideas: false,

    helpFaqLoading: true,

    initComponente: Ember.on('init', function () {
      getHelpFaq(this);
    }),

    actions: {
      openFaq: function openFaq(faq) {
        console.log(faq);
        this.set('objectFaqModal', faq);
        $("#helpFaqModal").modal('show');
      },
      tuto1: function tuto1() {
        $("#tuto1").modal('show');
      },
      tuto2: function tuto2() {
        $("#tuto2").modal('show');
      },
      tuto3: function tuto3() {
        $("#tuto3").modal('show');
      },
      tuto4: function tuto4() {
        $("#tuto4").modal('show');
      },
      tuto5: function tuto5() {
        $("#tuto5").modal('show');
      },
      tuto6: function tuto6() {
        $("#tuto6").modal('show');
      },
      showChips: function showChips() {
        this.set('showChips', true);
        this.set('showCampaign', false);
        this.set('startCampaign', false);
        this.set('scheduleCampaign', false);
        this.set('showOptout', false);
        this.set('showPublic', false);
        this.set('showChatbot', false);
        this.set('showMsgManager', false);
        this.set('showSendMideas', false);
      },
      showCampaign: function showCampaign() {
        this.set('showCampaign', true);
        this.set('showChips', false);
        this.set('startCampaign', false);
        this.set('scheduleCampaign', false);
        this.set('showOptout', false);
        this.set('showPublic', false);
        this.set('showChatbot', false);
        this.set('showMsgManager', false);
        this.set('showSendMideas', false);
      },
      startCampaign: function startCampaign() {
        this.set('showCampaign', false);
        this.set('showChips', false);
        this.set('startCampaign', true);
        this.set('scheduleCampaign', false);
        this.set('showOptout', false);
        this.set('showPublic', false);
        this.set('showChatbot', false);
        this.set('showMsgManager', false);
        this.set('showSendMideas', false);
      },
      scheduleCampaign: function scheduleCampaign() {
        this.set('showCampaign', false);
        this.set('showChips', false);
        this.set('startCampaign', false);
        this.set('scheduleCampaign', true);
        this.set('showOptout', false);
        this.set('showPublic', false);
        this.set('showChatbot', false);
        this.set('showMsgManager', false);
        this.set('showSendMideas', false);
      },
      showOptout: function showOptout() {
        this.set('showCampaign', false);
        this.set('showChips', false);
        this.set('startCampaign', false);
        this.set('scheduleCampaign', false);
        this.set('showOptout', true);
        this.set('showPublic', false);
        this.set('showChatbot', false);
        this.set('showMsgManager', false);
        this.set('showSendMideas', false);
      },
      showPublic: function showPublic() {
        this.set('showCampaign', false);
        this.set('showChips', false);
        this.set('startCampaign', false);
        this.set('scheduleCampaign', false);
        this.set('showOptout', false);
        this.set('showPublic', true);
        this.set('showChatbot', false);
        this.set('showMsgManager', false);
        this.set('showSendMideas', false);
      },
      showChatbot: function showChatbot() {
        this.set('showCampaign', false);
        this.set('showChips', false);
        this.set('startCampaign', false);
        this.set('scheduleCampaign', false);
        this.set('showOptout', false);
        this.set('showPublic', false);
        this.set('showChatbot', true);
        this.set('showMsgManager', false);
        this.set('showSendMideas', false);
      },
      showMsgManager: function showMsgManager() {
        this.set('showCampaign', false);
        this.set('showChips', false);
        this.set('startCampaign', false);
        this.set('scheduleCampaign', false);
        this.set('showOptout', false);
        this.set('showPublic', false);
        this.set('showChatbot', false);
        this.set('showMsgManager', true);
        this.set('showSendMideas', false);
      },
      showSendMideas: function showSendMideas() {
        this.set('showCampaign', false);
        this.set('showChips', false);
        this.set('startCampaign', false);
        this.set('scheduleCampaign', false);
        this.set('showOptout', false);
        this.set('showPublic', false);
        this.set('showChatbot', false);
        this.set('showMsgManager', false);
        this.set('showSendMideas', true);
      }
    }
  });
});