define('ember-js-getting-started/helpers/format-bool-sim-nao', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatBoolSimNao = formatBoolSimNao;
  var helper = Ember.Helper.helper;
  function formatBoolSimNao(params /*, hash*/) {
    var value = params[0];
    if (value === true) return 'Sim';else return 'Não';
  }

  exports.default = helper(formatBoolSimNao);
});