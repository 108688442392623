define('ember-js-getting-started/components/help-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _actions;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Component = Ember.Component;


  var getHelpVideo = function getHelpVideo(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/helps/video',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('helpVideoLoading', false);
        self.set('helpVideos', response);
      },
      failure: function failure(response) {
        self.set('helpVideoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('helpVideoLoading', false);
        console.log(response);
      }
    });
  };

  var getHelpFaq = function getHelpFaq(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/helps/faq',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('helpFaqLoading', false);
        self.set('helpFaqs', response);
      },
      failure: function failure(response) {
        self.set('helpFaqLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('helpFaqLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({
    routing: Ember.inject.service('-routing'),

    authenticationService: Ember.inject.service('auth-token-service'),

    helpVideoLoading: true,

    helpFaqLoading: true,

    objectVideoModal: null,

    objectFaqModal: null,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getHelpVideo(this, token);

      getHelpFaq(this, token);
    }),

    helpVideos: function () {
      return null;
    }.property('helpVideos'),

    helpFaqs: function () {
      return null;
    }.property('helpFaqs'),

    actions: (_actions = {
      tuto1: function tuto1() {
        $("#tuto1").modal('show');
      },
      tuto2: function tuto2() {
        $("#tuto2").modal('show');
      },
      tuto3: function tuto3() {
        $("#tuto3").modal('show');
      },
      tuto4: function tuto4() {
        $("#tuto4").modal('show');
      },
      tuto5: function tuto5() {
        $("#tuto5").modal('show');
      },
      tuto6: function tuto6() {
        $("#tuto6").modal('show');
      },
      openTutorialChipModal: function openTutorialChipModal() {
        $("#tutorialChipModal").modal('show');
      },
      openTutorialCampaignModal: function openTutorialCampaignModal() {
        $("#tutorialCampaignModal").modal('show');
      },
      openTutorialStartCampaignModal: function openTutorialStartCampaignModal() {
        $("#tutorialStartCampaignModal").modal('show');
      }
    }, _defineProperty(_actions, 'openTutorialStartCampaignModal', function openTutorialStartCampaignModal() {
      $("#tutorialStartCampaignModal").modal('show');
    }), _defineProperty(_actions, 'openTutorialScheduleCampaignModal', function openTutorialScheduleCampaignModal() {
      $("#tutorialScheduleCampaignModal").modal('show');
    }), _defineProperty(_actions, 'openTutorialChatbotModal', function openTutorialChatbotModal() {
      $("#tutorialChatbotModal").modal('show');
    }), _defineProperty(_actions, 'openTutorialImportModal', function openTutorialImportModal() {
      $("#tutorialImportModal").modal('show');
    }), _defineProperty(_actions, 'openTutorialSendModal', function openTutorialSendModal() {
      $("#tutorialSendModal").modal('show');
    }), _defineProperty(_actions, 'openFaq', function openFaq(faq) {
      console.log(faq);
      this.set('objectFaqModal', faq);
      $("#helpFaqModal").modal('show');
    }), _defineProperty(_actions, 'openVideo', function openVideo(video) {
      console.log(video);
      this.set('objectVideoModal', video);
      $("#helpVideoModal").modal('show');
    }), _defineProperty(_actions, 'toGeneral', function toGeneral() {
      this.get('routing').transitionTo('panel.general');
    }), _defineProperty(_actions, 'toUserArea', function toUserArea() {
      this.get('routing').transitionTo('panel.user-area');
    }), _defineProperty(_actions, 'toBillingInformation', function toBillingInformation() {
      this.get('routing').transitionTo('panel.billing-information');
    }), _defineProperty(_actions, 'toCredits', function toCredits() {
      this.get('routing').transitionTo('panel.credit');
    }), _defineProperty(_actions, 'toDownloads', function toDownloads() {
      this.get('routing').transitionTo('panel.download');
    }), _defineProperty(_actions, 'toHelp', function toHelp() {
      this.get('routing').transitionTo('panel.help');
    }), _defineProperty(_actions, 'toLearn', function toLearn() {
      this.get('routing').transitionTo('panel.learn');
    }), _defineProperty(_actions, 'toMachineManager', function toMachineManager() {
      this.get('routing').transitionTo('panel.machine-manager');
    }), _defineProperty(_actions, 'toUsers', function toUsers() {
      this.get('routing').transitionTo('panel.additional-user');
    }), _defineProperty(_actions, 'toAutoresponder', function toAutoresponder() {
      this.get('routing').transitionTo('panel.autoresponder-cota');
    }), _defineProperty(_actions, 'toAdditional', function toAdditional() {
      this.get('routing').transitionTo('panel.additional-user');
    }), _defineProperty(_actions, 'toBuyCredit', function toBuyCredit() {
      this.get('routing').transitionTo('panel.buy-credit');
    }), _defineProperty(_actions, 'toBuyAutoresponder', function toBuyAutoresponder() {
      this.get('routing').transitionTo('panel.buy-autoresponder');
    }), _defineProperty(_actions, 'toBuyMachine', function toBuyMachine() {
      this.get('routing').transitionTo('panel.buy-machine');
    }), _defineProperty(_actions, 'toNotifications', function toNotifications() {
      this.get('routing').transitionTo('panel.notification');
    }), _actions)

  });
});