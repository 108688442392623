define('ember-js-getting-started/components/infos-emojis-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    routing: Ember.inject.service('-routing'),

    emojiPessoas: true,
    objetos: false,
    comidasBebidas: false,
    atividades: false,
    viagensLugares: false,
    simbolos: false,
    animaisNatureza: false,

    actions: {
      goToObjetos: function goToObjetos() {
        this.set('emojiPessoas', false);
        this.set('objetos', true);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToEmojiPessoas: function goToEmojiPessoas() {
        this.set('emojiPessoas', true);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToComidasBebidas: function goToComidasBebidas() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', true);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToAtividades: function goToAtividades() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', true);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToViagensLugares: function goToViagensLugares() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', true);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToSimbolos: function goToSimbolos() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', true);
        this.set('animaisNatureza', false);
      },
      goToAnimaisNatureza: function goToAnimaisNatureza() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', true);
      },
      toInfosGeneral: function toInfosGeneral() {
        this.get('routing').transitionTo('infos.general');
      }
    }
  });
});