define('ember-js-getting-started/components/learn-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var getLearnEbook = function getLearnEbook(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/learns/ebook',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('learnEbookLoading', false);
        self.set('learnEbooks', response);
      },
      failure: function failure(response) {
        self.set('learnEbookLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('learnEbookLoading', false);
        console.log(response);
      }
    });
  };

  var getLearnVideo = function getLearnVideo(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/learns/video',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('learnVideoLoading', false);
        self.set('learnVideos', response);
      },
      failure: function failure(response) {
        self.set('learnVideoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('learnVideoLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({
    routing: Ember.inject.service('-routing'),

    authenticationService: Ember.inject.service('auth-token-service'),

    learnEbookLoading: true,

    learnVideoLoading: true,

    objectVideoModal: null,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getLearnEbook(this, token);
      getLearnVideo(this, token);
    }),

    learnEbooks: function () {
      return null;
    }.property('learnEbooks'),

    learnVideos: function () {
      return null;
    }.property('learnVideos'),

    actions: {
      openVideo: function openVideo(video) {
        console.log(video);
        this.set('objectVideoModal', video);
        $("#learnVideoModal").modal('show');
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      }
    }

  });
});