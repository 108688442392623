define('ember-js-getting-started/components/insta-account-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var getUser = function getUser(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('userInfoLoading', false);
        self.set('user', response);
      },
      failure: function failure(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    routing: Ember.inject.service('-routing'),

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getUser(this, token);
    }),

    user: function () {
      return null;
    }.property('user')

  });
});