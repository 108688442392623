define('ember-js-getting-started/routes/application', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;


  var verifyAuthentication = function verifyAuthentication(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/logins',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token
      },
      success: function success(response) {
        if (response.responseJSON.code === 200) {
          if (response.responseJSON.expired === false) {
            self.transitionTo('panel.general');
          } else {
            self.get('authenticationService').logoff();
            self.transitionTo('login');
          }
        } else {
          self.get('authenticationService').logoff();
          self.transitionTo('login');
        }
      },
      failure: function failure(response) {
        console.log(response);
        self.get('authenticationService').logoff();
        self.transitionTo('login');
      },
      error: function error(response) {
        console.log(response);
        self.get('authenticationService').logoff();
        self.transitionTo('login');
      }
    });
  };

  exports.default = Route.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    //Verificando autenticação 
    beforeModel: function beforeModel() {
      /*var version;
      if(window.clientInformation.userAgent.includes("x64")){
        version = "x64";
      }else{
        version = "x86";
      }
      console.log(version);*/

      /*var token = this.get('authenticationService').get();
      if(token !== undefined && token !== null && token !== ''){
        verifyAuthentication(this, token.token);
      }else{
        this.get('authenticationService').logoff(); 
        this.transitionTo('login'); 
      }*/
    },


    actions: {
      willTransition: function willTransition(transition) {
        console.log("will: " + transition);
        /*var token = this.get('authenticationService').get();
        var self = this;
        if(token !== undefined && token !== null && token !== ''){
          Ember.$.ajax({
            url: ENV.APP.connectfaceServiceUrl + '/logins',
            type: 'GET',
            dataType: 'json',
            async: true,     
            headers: {
              'Accept':'application/json',
              'Content-Type' :'application/json',
              'Auth': token
            },
            success: function(response){  
              console.log(response);
               console.log(response.status);
               if(response.status === 200){
                if(response.responseJSON.expired === false){
                  self.transitionTo('panel.general'); 
                }else{
                  self.get('authenticationService').logoff();
                  self.transitionTo('login'); 
                }
              }else{
                self.get('authenticationService').logoff();
                self.transitionTo('login'); 
              }
            },
            failure: function(response){         
              console.log(response);
              self.get('authenticationService').logoff();
              self.transitionTo('login'); 
            },
            error: function (response) {        
              console.log(response);
              self.get('authenticationService').logoff();
              self.transitionTo('login'); 
            }
          });
        }else{
          this.get('authenticationService').logoff(); 
          this.transitionTo('login'); 
        }*/
      }
    }
  });
});