define('ember-js-getting-started/components/general-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var getHashtags = function getHashtags(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/hashtags?text=' + self.get('searchHashtags'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalCreditsLoading', false);
        self.set('foundHashtags', response);
      },
      failure: function failure(response) {
        console.log(response);
        self.set('totalCreditsLoading', false);
      },
      error: function error(response) {
        console.log(response);
        self.set('totalCreditsLoading', false);
      }
    });
  };

  var getTotalCredits = function getTotalCredits(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/credits',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalCreditsLoading', false);
        self.set('totalCredits', response);
      },
      failure: function failure(response) {
        console.log(response);
        self.set('totalCreditsLoading', false);
      },
      error: function error(response) {
        console.log(response);
        self.set('totalCreditsLoading', false);
      }
    });
  };

  var getAutoresponderQuota = function getAutoresponderQuota(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/autoresponder',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalArLoading', false);
        self.set('totalAr', response);
      },
      failure: function failure(response) {
        console.log(response);
        self.set('totalArLoading', false);
      },
      error: function error(response) {
        console.log(response);
        self.set('totalArLoading', false);
      }
    });
  };

  var getUser = function getUser(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('userLoading', false);
        self.set('user', response);
      },
      failure: function failure(response) {
        self.set('userLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userLoading', false);
        console.log(response);
      }
    });
  };

  var getTotalUsers = function getTotalUsers(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users/additional/info',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalUsersLoading', false);
        self.set('totalUsers', response);
      },
      failure: function failure(response) {
        self.set('totalUsersLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('totalUsersLoading', false);
        console.log(response);
      }
    });
  };

  var getMachines = function getMachines(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/userMachineSlots/list',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('machinesLoading', false);
        self.set('machines', response);
      },
      failure: function failure(response) {
        self.set('machinesLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('machinesLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Component.extend({
    routing: Ember.inject.service('-routing'),
    authenticationService: Ember.inject.service('auth-token-service'),

    totalCreditsLoading: true,
    totalArLoading: true,
    machinesLoading: true,
    userLoading: true,
    totalUsersLoading: true,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getTotalCredits(this, token);
      getAutoresponderQuota(this, token);
      getMachines(this, token);
      getUser(this, token);
      getTotalUsers(this, token);

      this.set('plan', token.extras.user.planName);
    }),

    userAdditional: function () {
      return null;
    }.property('userAdditional'),

    isIaPlan: function () {
      if (this.get('plan') === "X") {
        return true;
      } else {
        return false;
      }
    }.property('isIaPlan'),

    totalCredits: function () {
      return null;
    }.property('totalCredits'),

    totalAr: function () {
      return null;
    }.property('totalAr'),

    totalMachines: function () {
      return null;
    }.property('totalMachines'),

    user: function () {
      return null;
    }.property('user'),

    totalUsers: function () {
      return null;
    }.property('totalUsers'),

    //variaveis de controle
    showTwoFactorsExemple: false,
    whatIsHashTag: false,
    findHashTag: false,
    findProfiles: false,
    isBusiness: false,

    //dados captados
    login: null,
    pass: null,
    business: null,
    gender: null,
    hashtags: null,
    profiles: null,

    //steps questionario
    step1Presentation: true,
    step2Verification: false,
    step2VerificationOk: false,
    step2VerificationNok: false,
    step3ConfigurationAccount: false,
    step4IsBusiness: false,
    step5Sex: false,
    step6Users: false,
    step6Profiles: false,

    actions: {
      selectHashtag: function selectHashtag(hashtag) {
        if (this.get('hashtags') === null || this.get('hashtags') === "") {
          this.set('hashtags', "#" + hashtag.name);
        } else {
          this.set('hashtags', this.get('hashtags') + ",#" + hashtag.name);
        }
      },
      searchHashtags: function searchHashtags() {
        if (this.get('searchHashtags') === null || this.get('searchHashtags') === "" || this.get('searchHashtags') === undefined) {
          alert('digite algo para buscar');
          $("#searHashtagsId").focus();
        } else {
          getHashtags(this, this.get('authenticationService').get());
        }
      },
      whatIsHashTag: function whatIsHashTag() {
        if (this.get('whatIsHashTag') === false) this.set('whatIsHashTag', true);else this.set('whatIsHashTag', false);
      },
      findProfiles: function findProfiles() {
        if (this.get('findProfiles') === false) this.set('findProfiles', true);else this.set('findProfiles', false);
      },
      findHashTag: function findHashTag() {
        if (this.get('findHashTag') === false) this.set('findHashTag', true);else this.set('findHashTag', false);
      },
      setIsBusiness: function setIsBusiness(type) {
        this.set('isBusiness', type);
        if (this.get('isBusiness') === false) this.set('business', 'Pessoal');else this.set('business', 'Comercial');
      },
      eraseLoginPass: function eraseLoginPass() {
        this.set('login', null);
        this.set('pass', null);
        $("#login").focus();
      },
      setStep2Verification: function setStep2Verification() {
        this.set('step1Presentation', false);
        this.set('step2Verification', true);
      },
      setStep2VerificationOk: function setStep2VerificationOk() {
        this.set('step1Presentation', false);
        this.set('step2Verification', false);
        this.set('step2VerificationOk', false);
        this.set('step2VerificationNok', false);
        this.set('step3ConfigurationAccount', true);
      },
      setStep2VerificationNok: function setStep2VerificationNok() {
        this.set('step2VerificationNok', true);
      },
      setStep3Ok: function setStep3Ok() {
        this.set('step1Presentation', false);
        this.set('step2Verification', false);
        this.set('step2VerificationOk', false);
        this.set('step2VerificationNok', false);
        this.set('step3ConfigurationAccount', false);
        this.set('step4IsBusiness', true);
      },
      setGender: function setGender(value) {
        this.set('gender', value);
      },
      setStep4Ok: function setStep4Ok() {
        this.set('step1Presentation', false);
        this.set('step2Verification', false);
        this.set('step2VerificationOk', false);
        this.set('step2VerificationNok', false);
        this.set('step3ConfigurationAccount', false);
        this.set('step4IsBusiness', false);
        this.set('step6Users', true);
      },
      setStep5Ok: function setStep5Ok() {
        this.set('step1Presentation', false);
        this.set('step2Verification', false);
        this.set('step2VerificationOk', false);
        this.set('step2VerificationNok', false);
        this.set('step3ConfigurationAccount', false);
        this.set('step5Sex', false);
        this.set('step5Hashtags', false);
        this.set('step6Users', true);
      },
      showTwoFactorsExemple: function showTwoFactorsExemple() {
        if (this.get('showTwoFactorsExemple') === false) this.set('showTwoFactorsExemple', true);else this.set('showTwoFactorsExemple', false);
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      },
      toEmojis: function toEmojis() {
        this.get('routing').transitionTo('panel.emoji');
      },
      toInstaAccount: function toInstaAccount() {
        this.get('routing').transitionTo('panel.insta-account');
      }
    }

  });
});